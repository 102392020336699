import BaseModel from "../core/BaseModel";

export default class PolicyModel extends BaseModel {
  constructor() {
    super(
      {
        name: "policy",
        tableName: "policy",
        label: "General Settings",
        display_key: "id",
      },
      {
        stt: { 
          name: "stt", 
          label: "No.",
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        policy_lang : {
          refs:"  policy_lang { name}",
          display:false
        },
        type: {
          name: "type",
          type: "String",
          label: "Settings name",
          class: "width_50",
          filter: false,
          display: false,
          // filter: {type: 'input', match: 'contains'},
        },
        content: {
          name: "content",
          type: "Text",
          label: "Settings name",
          class: "width_50",
          filter: {type: 'input', match: 'contains'}, 
        },
        created_at : { 
          name: 'created_at', 
          type: "Datetime", 
          label: 'Ngày tạo',
          display: false,

      },
      updated_at : { 
          name: 'updated_at', 
          type: "Datetime", 
          width: 12,
          label: 'Updated date',
          class: 'width_20',
        filter: {type: 'DateRange', match: 'contains'},
      },
      }
    );
  }
}
