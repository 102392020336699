import BaseModel from '../core/BaseModel'
export default class PostModel extends BaseModel {

    constructor() {
        super({ 
                name: "post",
                tableName: 'post',
                label: "Post Management", 
              }, 
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,
              display: false
            }, 
            type: {
              name: "type",
              type: "Selection",
              label: "Type of post",
              options:[
                { value: 'LOOKING_FOR_A_TEAM', label: 'Opponent'},
                { value: 'LOOKING_FOR_MERCENARY', label: 'Mercenary'},
                { value: 'SCOUT_CLUB_TEAM_MEMBER', label: 'Scout'},
                { value: 'APPLYING_TO_JOIN', label: 'Join club'},
                { value: 'POST_ON_MY_CLUB', label: 'Only my club'},
                { value: 'MATCH', label: 'Match'},
                { value: 'LIFE', label: 'Posts'},
                { value: 'SHOWING_OFF_YOUR_EQUIPMENT', label: 'Equipment'},
                { value: 'QUESTION', label: 'Question'},
              ],
              filter: {
                type: 'select',
                optionLabel: 'label',
                optionKey: 'value',
                match: 'equals'
              },
            },
            title: { 
              name: 'title', 
              type: "Text", 
              label: 'Title', 
              filter: {type: 'input', match: 'contains'},
            },
            caption: { 
              name: 'caption', 
              type: "Text", 
              label: 'Caption',
              filter: {type: 'input', match: 'contains'},
            },
            match_info : {
              name: 'match_info',
              type: "CustomData",
              label: 'Match',
              computed: (data) => {
                  data.match_info = '';
              },
              filter: {type: 'input', match: 'contains'},
            },
            clubs_name : {
              name: 'clubs_name',
              type: "CustomData",
              label: 'Club',
              computed: (data) => {
                  data.clubs_name = '';
              },
              filter: {type: 'input', match: 'contains'},
            },
            main_areas : {
              name: 'main_areas',
              type: "CustomData",
              label: 'Main active area',
              computed: (data) => {
                  data.main_areas = '';
              },
              filter: {type: 'input', match: 'contains'},
            },
            creator : {
              name: 'creator',
              type: "CustomData",
              label: 'Creator',
              computed: (data) => {
                data.creator = '';
              },
              filter: {type: 'input', match: 'contains'},
            },
            active: {
              name: "active",
              label: "Show",
              type: "Selection",
              custom: 'SelectionText',
              width: 5,
              options: [
                { value: true, label: 'Active',style: 'color:  #000000;'},
                { value: false, label: 'Unactive',style: 'color:  #000000;'},
              ],
              filter: {
              type: 'select',
              optionLabel: 'label',
              optionKey: 'value',
              match: 'equals'
              },
              class: "mid",
            },
            is_community : {
              name: "is_community",
              label: "Show",
              type: "Checkbox",
              display: false
            },
            text_main_active : {
              name: "text_main_active",
              label: "Main active",
              type: "TEXT",
              display: false
            },
            
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              // width: 10,
              label: 'Created date',
              filter: { type: 'DateRange', match: 'contains'},
            },
            rel_match: {
              refs: `match {
                  id date_time statdium_id stadium{id name}
                }`,
              display: false
            },
            rel_clubs: {
              refs: `clubs {
                  id club_name country{id country_lang(where: {lang: {_eq: "en"}}){name}} province{id province_lang(where: {lang: {_eq: "en"}}){name}}
                  district{id district_lang(where: {lang: {_eq: "en"}}){name}}
                }`,
              display: false
            },
            rel_creator: {
              refs: `creator{id fullname plain_fullname text_main_active}`,
              display: false
            },
            rel_post_main_area: {
              refs: `post_main_area{id province_id district_id 
                  province{id country{id country_lang(where: {lang: {_eq: "en"}}){name}} province_lang(where: {lang: {_eq: "en"}}){name}}
                  district{id district_lang(where: {lang: {_eq: "en"}}){name}}}`,
              display: false
            },
            rel_users_main_area: {
              refs: `users_main_area{id province_id district_id 
                  province{id country{id country_lang(where: {lang: {_eq: "en"}}){name}} province_lang(where: {lang: {_eq: "en"}}){name}}
                  district{id district_lang(where: {lang: {_eq: "en"}}){name}}}`,
              display: false
            },
            
        },)
    }

}