import BaseModel from "../core/BaseModel";

export default class ReportPostModel extends BaseModel {
  constructor() {
    super(
      {
        name: "report_post",
        tableName: "post_report",
        label: "Report post",
        display_key: "id",
      },
      {
        stt: {
          name: "stt",
          label: "No.",
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        type: {
          name: "type",
          label: "Type of post",
          type: "Selection",
          // custom: 'SelectionText',
            options:[
                { value: 'LOOKING_FOR_A_TEAM', label: 'Opponent'},
                { value: 'LOOKING_FOR_MERCENARY', label: 'Mercenary'},
                { value: 'SCOUT_CLUB_TEAM_MEMBER', label: 'Scout'},
                { value: 'APPLYING_TO_JOIN', label: 'Join club'},
                { value: 'POST_ON_MY_CLUB', label: 'Only my club'},
                { value: 'MATCH', label: 'Match'},
                { value: 'LIFE', label: 'Posts'},
                { value: 'SHOWING_OFF_YOUR_EQUIPMENT', label: 'Equipment'},
                { value: 'QUESTION', label: 'Question'},
            ],
          filter: {type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
          class:" width-1",
        },
          title: {
              name:"title",
              type: "Computed",
              label: "Title",
              custom: 'SelectionText',
              class:" width-1",
              filter: {type: 'input', match: 'contains'},
              computed: (data) => {
                  data.title = ''
              }
          },
          caption: {
              name:"caption",
              type: "Computed",
              label: "Caption",
              custom: 'SelectionText',
              class:" width-2 text-short",
              filter: {type: 'input', match: 'contains'},
              computed: (data) => {
                  data.caption = ''
              }
          },
          match: {
              name:"match",
              type: "Computed",
              label: "Match",
              custom: 'SelectionText',
              class:" width-1",
              filter: {type: 'input', match: 'contains'},
              computed: (data) => {
                  data.match = ''
              }
          },

          clubs: {
              name:"clubs",
              type: "Computed",
              label: "Club",
              custom: 'SelectionText',
              class:" width-1",
              filter: {type: 'input', match: 'contains'},
              computed: (data) => {
                  data.clubs = ''
              }
          },

          creator: {
              name:"creator",
              type: "Computed",
              label: "Creator",
              custom: 'SelectionText',
              class:" width-1",
              filter: {type: 'input', match: 'contains'},
              computed: (data) => {
                  data.creator = ''
              }
          },
          reason: {
              name:"reason",
              type: "Computed",
              label: "Reason",
              custom: 'SelectionText',
              class:" width-1",
              computed: (data) => {
                  data.reason = ''
              }
          },
          report_by: {
              name:"report_by",
              type: "Computed",
              label: "Report by",
              custom: 'SelectionText',
              class:" width-1",
              filter: {type: 'input', match: 'contains'},
              computed: (data) => {
                  data.report_by = ''
              }
          },
          created_at : {
              name: 'crated_at',
              type: "Datetime",
              width: 12,
              label: 'Reported date',
              class:" width-1",
              filter: {type: 'DateRange', match: 'contains'},
          },
          post_id: {
            name: "post_id",
            type: "Uuid",
            label: "post_id",
            display: false,
          },
          rel_data :{ 
            refs : `post {id type title caption creator{id fullname plain_fullname} clubs{id club_name}}
            reason_arr(where: {lang: {_eq: "en"}}){name lang}
            report_by{fullname}
            `,
            display: false
          }
        //   post: {
        //       refs:`post { id type title caption creator{fullname plain_fullname}} 
        //       reason_arr(where: {lang: {_eq: "en"}}){name lang}` +
        //           ` clubs{club_name province{province_lang(where: {lang: {_eq: "en"}}){name}}} match{ created_at stadium{name}} clubs{id} }`,
        //       display: false
        //   },
      //   fullname: {
      //     name: "fullname",
      //     label: "Fullname",
      //     type: "Computed",
      //     custom: 'SelectionText',
      //     width : 5,
      //     class: 'center width-1',
      //     filter: {type: 'input', match: 'contains'},
      //     computed: (data) => {
      //       console.log("fullname")
      //       data.fullname = data.users.fullname ?  data.users.fullname:"-"
      //     }
      //   },
      //   email: {
      //     name:"email",
      //     type: "Computed",
      //     label: "Email",
      //     custom: 'SelectionText',
      //     class:" width-1",
      //     filter: {type: 'input', match: 'contains'},
      //     computed: (data) => {
      //       console.log("fullname")
      //       data.email = data.users.email ?  data.users.email:"-"
      //     }
      //   },
      //   main_active_area: {
      //     name:"main_active_area",
      //     type: "Computed",
      //     label: "Main active area",
      //     custom: 'SelectionText',
      //     class:" width-1",
      //     filter: {type: 'input', match: 'contains'},
      //     computed: (data) => {
      //       data.main_active_area = ""
      //     }
      //   },
      //   users: {
      //     refs:"users { fullname email}",
      //     display:false
      //   },
      //   reason: {
      //     refs:"reason { name lang }",
      //     type: "Selection",
      //     label: "Reason",
      //     custom: 'SelectionText',
      //     class:" width-1",
      //   },
      //
      //   report_by: {
      //     refs:"report_by { fullname }",
      //     type: "Selection",
      //     label: "Report by",
      //     custom: 'SelectionText',
      //     class:" width-1",
      //     filter: {type: 'input', match: 'contains'},
      //   },
      //
      //   deleted: {
      //     name: "deleted",
      //     type: "String",
      //     label: "Guide name",
      //     display: false
      //   },
      //   created_at : {
      //     name: 'created_at',
      //     type: "Datetime",
      //     label: 'Ngày tạo',
      //     display: false,
      //
      // },

      //
      //   rel_users_main_area: {
      //     refs: `users { usere_main_area{id district_id
      //     district{id district_lang(where: {lang: {_eq: "en"}}){name}}
      //       province{id province_lang(where: {lang: {_eq: "en"}}){name}}}}`,
      //     display: false
      //   },



      }
    );
  }
}
