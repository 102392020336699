import BaseModel from "../core/BaseModel";

export default class ReportReasonModel extends BaseModel {
  constructor() {
    super(
      {
        name: "report_reason",
        tableName: "report_reason",
        label: "Reason list",
        display_key: "id",
      },
      {
        stt: { 
          name: "stt", 
          label: "No.",
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },

        report_reason_lang : {
          refs:" report_reason_lang { report_reason_id lang  name plain_name }",
          name: "report_reason_lang",
          type: "Selection",
          label: "Reason",
          custom: 'SelectionText',
          class:"width-3",
          filter: {type: 'input', match: 'contains'},
        },
        type: {
          name: "type",
          label: "Type",
          type: "Selection",
          custom: 'SelectionText',
          options: [
            {value: 'REPORT_USER', label: 'Report user', style: 'color:  #000000;'},
            {value: 'REPORT_POST', label: 'Report post',style: 'color:  #000000;'},
            {value: 'REPORT_OTHER', label: 'Report other',style: 'color:  #000000;'}
          ],
          filter: {type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
          class:" width-1",
        },
        rank: {
          name: "rank",
          type: "Integer",
          label: "Rank",
          class: "mid width-1",
        },
        active: {
          name: "active",
          label: "Show",
          type: "Selection",
          custom: 'SelectionText',
          options: [
            {value: 'true', label: 'Active', style: 'color:  #000000;'},
            {value: 'false', label: 'Unactive',style: 'color:  #000000;'}
          ],
          filter: {type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
          class:"mid width-1",
        },
        deleted: {
          name: "deleted",
          type: "String",
          label: "Guide name",
          display: false
        },
        created_at : { 
          name: 'created_at', 
          type: "Datetime", 
          label: 'Ngày tạo',
          display: false,

      },
        updated_by_id: {
          refs:"updated_by_id { fullname}",
          type: "Selection",
          label: "Updated by",
          custom: 'SelectionText',
          class:" width-1",
        },
      updated_at : { 
          name: 'updated_at', 
          type: "Datetime", 
          width: 12,
          label: 'Updated date',
        class:" width-1",
        filter: {type: 'DateRange', match: 'contains'},
      },

      }
    );
  }
}
