import BaseModel from '../core/BaseModel'
import StadiumModel from './StadiumModel'
import NumberOfPlayerModel from './NumberOfPlayerModel'
export default class MatchModel extends BaseModel {

    constructor() {
        super({ 
                name: "match",
                tableName: 'match',
                label: "Match Management", 
              }, 
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            clubs: {
              refs: "clubs{id club_name}",
              display: false
            },
            competition_clubs: {
              refs: "competition_clubs{id club_name}",
              display: false
            },
            owner: {
              refs: "owner{id fullname}",
              display: false
            },
            clubs_name: { 
              name: 'clubs_name', 
              type: "String", 
              label: 'Match created team',
              filter: {type: 'input', match: 'contains'},
              width: 20,
              computed: (data) => {
                data.clubs_name = "";
              }
            },
            competition_clubs_name: {
              name: 'competition_clubs_name', 
              type: "String", 
              label: 'Competition team', 
              width: 20,
              filter: {type: 'input', match: 'contains'},
              computed: (data) => {
                data.competition_clubs_name = "";
              }
            },
            owner_name: {
              name: 'owner_name', 
              type: "String", 
              label: 'Creator', 
              width: 10,
              filter: {type: 'input', match: 'contains'},
              computed: (data) => {
                data.owner_name = "";
              }
            },
            date_time : { 
              name: 'date_time', 
              type: "Datetime", 
              width: 10,
              label: 'Date & time',
              filter: { type: 'DateRange', match: 'contains'},
            },
            statdium_id: { 
              name: 'statdium_id', 
              type: "Selection", 
              label: 'Statdium', 
              models: new StadiumModel(),
              filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            },
            score_club_1: {
              name: 'score_club_1',
              type: "Bigint",
              label: 'score_club_1',
              display: false
            },
            score_club_2: {
              name: 'score_club_2',
              type: "Bigint",
              label: 'score_club_2',
              display: false
            },
            number_of_player_id: { 
              name: 'number_of_player_id', 
              type: "Selection", 
              label: 'Number of player', 
              models: new NumberOfPlayerModel(),
              filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' }
            },
            gender: { 
              name: 'gender', 
              type: "Selection", 
              label: 'Gender',
              options: [{ value: 1, label: 'Male'}, { value: 2, label: 'Female'}, { value: 3, label: 'Mix'}],
              filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            },
            match_member: {
              refs: "match_member{id}",
              display: false
            },
            member: {
              name: 'member', 
              type: "CustomUrl", 
              label: 'Member',
              class: 'center',
              computed: (data) => {
                data.member = 0;
              }
            },
            score: {
              name: 'score', 
              type: "String", 
              label: 'Score',
              computed: (data) => {
                data.score = '';
              }
            },
            status: {
              name: 'status',
              type: "Selection",
              label: 'Status',
              options: [{ value: 'PROCESSING', label: 'Processing'}, { value: 'UPCOMING', label: 'Upcoming'}, { value: 'MATCHED', label: 'Matched'}],
              filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
              
            },
            created_at : { 
                name: 'created_at', 
                type: "Datetime", 
                width: 10,
                label: 'Created date',
                filter: { type: 'DateRange', match: 'contains'}
            },
                
        },)
    }

}