import BaseModel from "../core/BaseModel";
import CountryModel from "./CountryModel";
import ProvinceModel from "./ProvinceModel";
import DistrictModel from "./DistrictModel";
import WardModel from "./WardModel";

export default class BannerModel extends BaseModel {
  constructor() {
    super(
      {
        name: "stadium",
        tableName: "stadium",
        label: "Stadium",
        display_key: "name",
      },
      {
        stt: { 
          name: "stt", 
          label: "No.",
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        name: {
          name: "name",
          label: "Stadium",
          type: "String",
          filter: {type: 'input', match: 'contains'},
        },
        plain_name: {
          name: "plain_name",
          label: "plain_name",
          type: "String",
          display: false,
        },
        name_country: { 
          name: "name_country", 
          label: "Country", 
          width: 8,
          type: "Selection",
          custom: 'SelectionText',
          models: new CountryModel(),
          filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
          computed: (data) => {
            data.name_country = '';
          }
        },
        name_province: { 
          name: "name_province", 
          label: "City/Province", 
          width: 8,
          type: "Selection",
          custom: 'SelectionText',
          models: new ProvinceModel(),
          filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
          computed: (data) => {
            data.name_province = '';
          }
        },
        name_district: { 
          name: "name_district", 
          label: "District", 
          width: 8,
          type: "Selection",
          custom: 'SelectionText',
          models: new DistrictModel(),
          filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
          computed: (data) => {
            data.name_district = '';
          }
        },
        name_ward: { 
          name: "name_ward", 
          label: "Ward", 
          width: 8,
          type: "Selection",
          custom: 'SelectionText',
          models: new WardModel(),
          filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
          computed: (data) => {
            data.name_ward = '';
          }
        },
        street: {
          name: "street",
          label: "Street",
          type: "String",
          filter: {type: 'input', match: 'contains'},
        },
        created_at : {
          name: 'created_at',
          type: "Datetime",
          // width: 20,
          label: 'Create date',
          filter: {type: 'DateRange', match: 'contains'},
        },
        active: {
          name: "active",
          label: "Show",
          type: "Selection",
          custom: 'SelectionText',
          options: [
            {value: 'true', label: 'Active', style: 'color:  #000000;'},
            {value: 'false', label: 'Unactive',style: 'color:  #000000;'}
          ],
          filter: {type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
          class:"mid",
        },
        country_id : {
          name: "country_id",
          label: "country_id",
          type: "String",
          display: false,
        },
        province_id : {
          name: "province_id",
          label: "province_id",
          type: "String",
          display: false,
        },
        district_id : {
          name: "district_id",
          label: "district_id",
          type: "String",
          display: false,
        },
        updated_at : {
          name: 'updated_at',
          type: "Datetime",
          width: 12,
          label: 'Updated date',
          class:" width-1",
          display: false,
        },

        deleted: {
          name: "deleted",
          type: "String",
          label: "Deleted",
          display: false
        },
        rel_data : {
          refs: `
            country{id country_lang(where: {lang: {_eq: "en"}}){lang name}}
            province{id province_lang(where: {lang: {_eq: "en"}}){lang name}}
            district{id district_lang(where: {lang: {_eq: "en"}}){lang name}}
            ward{id ward_lang(where: {lang: {_eq: "en"}}){lang name}}
          `,
          display: false
        }
        
      }
    );
  }
}
