import BaseModel from '../core/BaseModel'

export default class NotificationsLangModel extends BaseModel {

    constructor() {
        super({
            tableName: 'lang_notification', label: "Notification Lang", display_key: 'notification_id'
        }, {
            notification_id: { 
                name: 'notification_id', 
                type: "Uuid", 
                label: 'ID', 
                display: false
            },
            title: { 
                name: 'title', 
                type: "Text", 
                label: 'Title',  
                width: 20,
                display: false
            },
            description: { 
                name: 'description', 
                type: "Text", 
                label: 'Nội dung',
                width: 80,
            },
            short_description: { 
                name: 'short_description', 
                type: "String", 
                label: 'Nội dung',
                width: 80,
                display: false
            },
            short_push: { 
                name: 'short_push', 
                type: "String", 
                label: 'Nội dung gửi push',
                width: 80,
                display: false
            },
        })
    }

}