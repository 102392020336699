import BaseModel from "../core/BaseModel";

export default class PolicyLangModel extends BaseModel {
  constructor() {
    super(
      {
        name: "policy_lang",
        tableName: "policy_lang",
        label: "Ngôn ngữ chính sách",
        display_key: "policy_id",
      },

      {
        policy_id: {
          name: "policy_id",
          type: "bigint",
          label: "ID",
          primaryKey: true,
        },
        lang: {
          name: "lang",
          type: "Text",
          label: "Nội dung",
          display: false,
        },
        name: {
          name: "name",
          type: "Text",
          label: "Nội dung",
          display: false,
        },
        infomation: {
          name: "infomation",
          type: "Text",
          label: "Nội dung",
          display: false,
        },
      }
    );
  }
}
