import BaseModel from "../core/BaseModel";

export default class ReportUserModel extends BaseModel {
  constructor() {
    super(
      {
        name: "report_user",
        tableName: "post_report",
        label: "Report user",
        display_key: "id",
      },
      {
        stt: {
          name: "stt",
          label: "No.",
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        fullname: {
          name: "fullname",
          label: "Fullname",
          type: "Computed",
          custom: 'SelectionText',
          width : 5,
          class: ' width-1-report-user',
          filter: {type: 'input', match: 'contains'},
          computed: (data) => {
            console.log("fullname")
            data.fullname = data.users.fullname ?  data.users.fullname:"-"
          }
        },
        email: {
          name:"email",
          type: "Computed",
          label: "Email",
          custom: 'SelectionText',
          class:" width-1-report-user",
          filter: {type: 'input', match: 'contains'},
          computed: (data) => {
            console.log("fullname")
            data.email = data.users.email ?  data.users.email:"-"
          }
        },
        main_active_area: {
          name:"main_active_area",
          type: "Computed",
          label: "Main active area",
          custom: 'SelectionText',
          class:" width-1-report-user",
          filter: {type: 'input', match: 'contains'},
          computed: (data) => {
            data.main_active_area = ""
          }
        },
        users: {
          refs:"users { fullname email}",
          display:false
        },
        reason_arr: {
          refs:`reason_arr  (where: {lang: {_eq: "en"}}) {name lang }`,
          type: "Selection",
          label: "Reason",
          custom: 'SelectionText',
          class:" width-1-report-user",
        },
        reason: {
          refs:`reason {name lang }`,
         display:false
        },

        report_by: {
          refs:"report_by { fullname }",
          type: "Selection",
          label: "Report by",
          custom: 'SelectionText',
          class:" width-1-report-user",
          filter: {type: 'input', match: 'contains'},
        },

        deleted: {
          name: "deleted",
          type: "String",
          label: "Guide name",
          display: false
        },
        created_at : {
          name: 'created_at',
          type: "Datetime",
          label: 'Ngày tạo',
          display: false,

      },
      updated_at : {
          name: 'updated_at',
          type: "Datetime",
          width: 12,
          label: 'Reported date',
        class:" width-1-report-user",
        filter: {type: 'DateRange', match: 'contains'},
      },

        rel_users_main_area: {
          refs: ` reason_text users { usere_main_area{id province_id district_id
          district{id district_lang(where: {lang: {_eq: "en"}}){name}}
            province{id province_lang(where: {lang: {_eq: "en"}}){name}}}} report_reason{type} `,
          display: false
        },


      }
    );
  }
}
