import Vue from 'vue';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config/PrimeVue';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import Chip from 'primevue/chip';
import Column from 'primevue/column';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
// import Fieldset from 'primevue/fieldset';
import Avatar from 'primevue/avatar';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import BlockUI from 'primevue/blockui';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import JsonExcel from "./views/JsonExcel.vue";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import vSelect from 'vue-select';

import 'primevue/resources/themes/nova/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import './assets/layout/layout.scss';
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss"
// import { createProvider,onLogin} from './vue-apollo'//, onLogin, onLogout

import underscore from 'vue-underscore';

import VModal from 'vue-js-modal'
Vue.use(VModal, { dynamic: true, injectModalsContainer: true, dialog: true })


Vue.use(PrimeVue, {ripple: true});
Vue.use(ConfirmationService);

Vue.use(ToastService);
Vue.directive('tooltip', Tooltip);
Vue.use(require('vue-moment'));
Vue.use(underscore)

Vue.config.productionTip = false;

Vue.component('ConfirmDialog', ConfirmDialog);
Vue.component('Accordion', Accordion);
Vue.component('AccordionTab', AccordionTab);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Avatar', Avatar);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('Button', Button);
Vue.component('BlockUI', BlockUI);
Vue.component('Calendar', Calendar);
Vue.component('Card', Card);
Vue.component('Carousel', Carousel);
Vue.component('Chart', Chart);
Vue.component('Checkbox', Checkbox);
Vue.component('Chips', Chips);
Vue.component('Chip', Chip);
Vue.component('Column', Column);
Vue.component('ContextMenu', ContextMenu);
Vue.component('DataTable', DataTable);
Vue.component('DataView', DataView);
Vue.component('DataViewLayoutOptions', DataViewLayoutOptions);
Vue.component('Dialog', Dialog);
Vue.component('Dropdown', Dropdown);
Vue.component('Editor', Editor);
// Vue.component('Fieldset', Fieldset);
Vue.component('FileUpload', FileUpload);
Vue.component('FullCalendar', FullCalendar);
Vue.component('Inplace', Inplace);
Vue.component('InputSwitch', InputSwitch);
Vue.component('InputText', InputText);
Vue.component('InputMask', InputMask);
Vue.component('InputNumber', InputNumber);
Vue.component('Listbox', Listbox);
Vue.component('MegaMenu', MegaMenu);
Vue.component('Menu', Menu);
Vue.component('Menubar', Menubar);
Vue.component('Message', Message);
Vue.component('MultiSelect', MultiSelect);
Vue.component('OrderList', OrderList);
Vue.component('OrganizationChart', OrganizationChart);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('Paginator', Paginator);
Vue.component('Panel', Panel);
Vue.component('PanelMenu', PanelMenu);
Vue.component('Password', Password);
Vue.component('PickList', PickList);
Vue.component('ProgressBar', ProgressBar);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('RadioButton', RadioButton);
Vue.component('Rating', Rating);
Vue.component('SelectButton', SelectButton);
Vue.component('Slider', Slider);
Vue.component('Sidebar', Sidebar);
Vue.component('SplitButton', SplitButton);
Vue.component('Steps', Steps);
Vue.component('TabMenu', TabMenu);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('Textarea', Textarea);
Vue.component('TieredMenu', TieredMenu);
Vue.component('Toast', Toast);
Vue.component('Toolbar', Toolbar);
Vue.component('ToggleButton', ToggleButton);
Vue.component('Tree', Tree);
Vue.component('TreeTable', TreeTable);
Vue.component('TriStateCheckbox', TriStateCheckbox);
Vue.component('vue-google-autocomplete', VueGoogleAutocomplete );
Vue.component("ExportExcel", JsonExcel);
Vue.component('v-select', vSelect)
Vue.use( CKEditor );
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//import * as firebase from 'firebase/app'
import store from './store'
//import config from './config/index'
import configJson from '../config.json'
import commonFuction from './core/Common'
import constants from './core/Constants'
import CoreService from "./core/CoreService";
import CommonList from "./core/CommonList";
import AuthenticationApp from "./core/AuthenticationApp";
//import * as VueGoogleMaps from "vue2-google-maps";

var envConfig = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') ? process.env.NODE_ENV:'development';
console.log("process.env.NODE_ENVprocess.env.NODE_ENV:",process.env.NODE_ENV);
console.log("envConfigenvConfigenvConfig:",envConfig);
/*
Vue.use(VueGoogleMaps, {
  load: config.googleMaps(),
  installComponents: true
});*/
/*
const firebaseConfig = configJson[envConfig].firebaseConfig;
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().onAuthStateChanged(function (user) {
  // console.log(user)
  if (user) {
    store.commit('setUser', user.uid)
    store.commit('setIsLoggedIn', true)
  } else {
    // User is signed out.
    // ...
    // console.log('User logout')
    store.commit('setUser', null)
    store.commit('setIsLoggedIn', false)
    store.commit('setToken', null)
    onLogout(null)
  }
})

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}*/
// AuthenticationApp.getStateLogin().then(async state_login => {
//   if (commonFuction.isEmpty(state_login)){
//     //onLogout(null);
//     // var url_current = window.location.href;
//     // if ((url_current.search("auth/login")) == -1){
//     //   //Nếu trang hiện tại ko phải trang login thì logout ra trang login || còn đang ở trang login thì ko cần logout
//     //   AuthenticationApp.clearDataLogout();
//     // }
//     return false;
//   }
//   var token = state_login.token
//   var minus_time = moment().tz("UTC").subtract(60*10, 'seconds').format('YYYYMMDDHHmmss'); //-10phut //60*10
//   var dateChangeToken = state_login.dateChangeToken;
//   if (parseFloat(minus_time) < parseFloat(dateChangeToken)) {
//     //Function này để check trường hợp đổi password thì logout tất cả các thiết bị || Nếu thời gian đăng nhập > (thời gian hiện tại -10 phút) thì check
//     await AuthenticationApp.checkToken(token).then(async (res) => {
//       if (res) {
//         //token tồn tại
//         store.commit('setToken', token)
//         store.commit('setUser', state_login.uid)
//         store.commit('setIsLoggedIn', true)
//       }
//     })
//   }
//   //console.log('token---->',token);
//   //Set token cho page

//   //onLogin(null, token)
//   //Trường hợp đóng trình duyệt để lâu ngày mở lại thì refesh nếu > 10 phút
//   AuthenticationApp.refeshToken();
// })

// //refesh token
// setInterval(function(){
//   //Gọi API refeshtoken
//   AuthenticationApp.refeshToken();
//   },1000*60 ); //1 phút refesh 1 lần để kiểm tra

router.beforeEach(async (to, from, next) => {
  document.title = "Kickdun";
  next();
})
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
Vue.use(CoolLightBox)

import i18n from './i18n'
import {helpers} from './helpers'
// import moment from "moment-timezone";

Vue.prototype.$commonFuction = commonFuction;
Vue.prototype.$constants = constants.getConstans();
Vue.prototype.$CoreService = CoreService;
Vue.prototype.$CommonList = CommonList;
Vue.prototype.$AuthenticationApp = AuthenticationApp;
Vue.prototype.$ConfigEvn = configJson[envConfig];

new Vue({
  router,
  // apolloProvider: createProvider(),
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
Vue.prototype.$helpers = helpers