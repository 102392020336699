import BaseModel from "../core/BaseModel";

export default class GuideModel extends BaseModel {
  constructor() {
    super(
      {
        name: "guide",
        tableName: "guide",
        label: "User's guide",
        display_key: "id",
      },
      {
        stt: { 
          name: "stt", 
          label: "No.",
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },

        guide_lang : {
          refs:" guide_lang { infomation lang plain_title title guide_id }",
          name: "guide_lang",
          type: "Selection",
          label: "Guide name",
          custom: 'SelectionText',
          class:"width_50 common",
          filter: {type: 'input', match: 'contains'},
        },
        active: {
          name: "active",
          type: "String",
          label: "Guide name",
          display: false
        },
        deleted: {
          name: "deleted",
          type: "String",
          label: "Guide name",
          display: false
        },
        created_at : { 
          name: 'created_at', 
          type: "Datetime", 
          label: 'Ngày tạo',
          display: false,

      },
      updated_at : { 
          name: 'updated_at', 
          type: "Datetime", 
          width: 12,
          label: 'Updated date',
        filter: {type: 'DateRange', match: 'contains'},
      },
      }
    );
  }
}
