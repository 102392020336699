import BaseModel from '../core/BaseModel';
import SkillModel from './SkillModel';
export default class ClubsModel extends BaseModel {
    constructor() {
        super({ 
                name: "clubs",
                tableName: 'clubs', 
                label: "Club Management", 
                display_key: 'name', 
                default_order: { key: 'created_at', direction: 'asc' } ,
            },  
            {
            stt: { 
                name: "stt", 
                label: "No.",
                width: 5,
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            // image : {
            //     name: 'image', 
            //     type: "Image",
            //     label: 'Ảnh đại diện',
            //     // inputOptions: { mode: 'multiple' },
            //     accessable: "image/*"
            // },
            club_name : { 
                name: 'club_name', 
                type: "Text", 
                label: 'Club name', 
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                width : 15,
            },
            skill: {
                refs: "skill{id skill_lang{name}}",
                display: false
            },
            skill_id: {
                name: 'skill_id', 
                label: 'Club skill', 
                type: "Selection",
                models: new SkillModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            },
            club_operating_time: {
                refs: `club_operating_time { operating_time { id operating_time_lang (where: {lang: {_eq: "en"}}) {name}}}`,
                display:false
            },
            clubs_member: {
                refs: `clubs_member{uid role users{fullname}}`,
                display: false
            },
            district: {
                refs: `district{id district_lang(where: {lang: {_eq: "en"}}) {name}}`,
                display: false
            },
            province: {
                refs: `province{id province_lang(where: {lang: {_eq: "en"}}) {name}}`,
                display: false
            },
            country: {
                refs: `country{id country_lang(where: {lang: {_eq: "en"}}) {name}}`,
                display: false
            },
            address: {
                name: 'address',
                type: "Text",
                label: 'Main active area', 
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.address = "";
                },
                width: 15
            },
            owner_name: {
                name: 'owner_name',
                type: "Text",
                label: 'Club owner', 
                width : 15,
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.owner_name = "";
                }
            },
            club_operating_time_name: {
                name: 'club_operating_time_name',
                type: "Text",
                label: 'Operating time', 
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.club_operating_time_name = "";
                }
            },
            
            currency_code: {
                name: 'currency_code',
                type: "Text",
                display: false
            },
            
            schedule: {
                name: 'schedule',
                type: "Text",
                display: false
            },
            
            membership_fee: {
                name: 'membership_fee',
                type: "Text",
                display: false
            },
            
            schedule_fee: {
                name: 'schedule_fee',
                type: "Text",
                label: 'Fee - schedule', 
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                }
            },
            
            members: {
                name: 'members',
                type: "CustomUrl",
                label: 'Member',
                class: "center",
                computed: (data) => {
                    data.members = 0;
                }
            },
            
            status: {
                name: 'status',
                type: "Selection",
                label: 'Status',
                options: [
                    {
                        "value": "active",
                        "label": "Active"
                    },
                    {
                        "value": "unactive",
                        "label": "Unactive"
                    },
                    {
                        "value": "deleted",
                        "label": "Deleted"
                    }
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            },
            
            is_ban: {
                name: 'is_ban',
                type: "Selection",
                label: 'Banned',
                options: [
                    {
                        "value": "BANNED",
                        "label": "Banned"
                    },
                    {
                        "value": "NONE",
                        "label": "UnBanned"
                    }
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            },
            
            created_at: {
                name: 'created_at',
                type: "Datetime",
                label: 'Created date',
                filter: { type: 'DateRange', match: 'contains'},
            },
            
            
        },)
    }

}