import BaseModel from "../core/BaseModel";

export default class ReportReasonLangModel extends BaseModel {
  constructor() {
    super(
      {
        name: "report_reason_lang",
        tableName: "report_reason_lang",
        label: "report_reason_lang",
        display_key: "report_reason_id",
      },
      {

        report_reason_id: {
          name: "report_reason_id",
          type: "bigint",
          label: "ID",
          primaryKey: true,
        },
        lang: {
          name: "lang",
          type: "Text",
          label: "Nội dung",
          display: false,
        },
        name: {
          name: "name",
          type: "Text",
          label: "Tên",
          display: false,
        },
        plain_name: {
          name: "plain_name",
          type: "Text",
          label: "Nội dung",
          display: false,
        },
      }
    );
  }
}
