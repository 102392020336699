import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class UserModel extends BaseModel {

    constructor() {
        super({
                name: "user",
                tableName: 'users',
                label: "User Management",
                /* display_key: 'fullname',
                 default_order: { key: 'updated_at', direction: 'asc' }*/
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
                fullname: { 
                    name: 'fullname',
                    type: "String",
                    label: 'Fullname',
                    // width: 250,
                    filter: {type: 'input', match: 'contains'},
                },
                type_login: {
                    name: "type_login",
                    label: "Login Type",
                    type: "Selection",
                    custom: 'SelectionText',
                    width : 125,
                    options: [
                      { value: 'EMAIL', label: 'Email',style: 'color:  #000000;'},
                      { value: 'FACEBOOK', label: 'Facebook',style: 'color:  #000000;'},
                      { value: "GOOGLE", label: 'Google',style: 'color:  #000000;'},
                      { value: 'APPLE', label: 'Apple ID',style: 'color:  #000000;'},
                    ],
                    filter: {
                      type: 'select',
                      optionLabel: 'label',
                      optionKey: 'value',
                      match: 'equals'
                    },
                    computed: (data) => {
                      data.type_login = '';
                    }
                },
                phone: { 
                    name: 'phone',
                    type: "String",
                    label: 'Số điện thoại',
                    sortable: false,
                    width: 100,
                    filter: {
                        type: 'input',
                        match: 'contains'
                    },
                    display : false
                },
                email : { 
                    name: 'email',
                    type: "TEXT",
                    label: 'Email',
                    // width: 250,
                    filter: {type: 'input', match: 'contains'},
                },
                number_clubs : {
                    name: 'number_clubs',
                    type: "CustomData",
                    label: 'Club',
                    class: "center",
                    width : 100,
                    // class : "cus-number",
                    computed: (data) => {
                        data.number_clubs = '';
                    }
                    // display: false
                },
                main_areas : {
                    name: 'main_areas',
                    type: "CustomData",
                    label: 'Main active area',
                    // width: 250,
                    computed: (data) => {
                        data.main_areas = '';
                    },
                    filter: {type: 'input', match: 'contains'},
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                
                status: { 
                    name: 'status',
                    label: 'status',
                    width: 10,
                    type: "Int",
                    display: false
                },
                experience_id: {
                    name: 'experience_id',
                    label: 'Experience',
                    type: "Bigint",
                    display: false
                },
                country_id: {
                    name: 'country_id',
                    label: 'Country',
                    type: "Bigint",
                    display: false
                },
                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    width: 125,
                    options: [
                        { value: true, label: 'Active',style: 'color:  #000000;'},
                        { value: false, label: 'Unactive',style: 'color:  #000000;'},
                    ],
                    filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                    },
                    class: "mid",
                    // display : false
                },
                deleted: {
                    name: "deleted",
                    label: "Xóa",
                    type: "Checkbox",
                    display: false
                },
                created_at : { 
                    name: 'created_at', 
                    type: "Datetime", 
                    label: 'Created date',
                    filter: { type: 'DateRange', match: 'contains'},
                },
                updated_at : { 
                    name: 'updated_at', 
                    type: "CustomDate", 
                    label: 'Ngày cập nhật',
                    display: false, 
                    sortable: true,  
                    filter: false,
                },
                role : {
                    name: 'role',
                    type: "String",
                    label: 'role',
                    display: false
                },
                rel_accounts: {
                    refs: 'accounts{type}',
                    display: false
                },
                rel_users_main_area: {
                    refs: `usere_main_area{id province_id district_id 
                        province{id province_lang(where: {lang: {_eq: "en"}}){name}}
                        district{id district_lang(where: {lang: {_eq: "en"}}){name}}}`,
                    display: false
                },
                rel_clubs_member: {
                    refs: `clubs_member_aggregate(where: {status: {_eq: "approved"}}) {
                            aggregate {
                                count
                            }
                        }`,
                    display: false
                }

            },
            )
    }

}