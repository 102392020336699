import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: () => import('../views/Front'),
    component: () => import("@/views/pages/LandingPage/index"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/en',
    name: 'home',
    // component: () => import('../views/Front'),
    component: () => import("@/views/pages/LandingPage/index"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/vi',
    name: 'home',
    // component: () => import('../views/Front'),
    component: () => import("@/views/pages/LandingPage/index_vn"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/kr',
    name: 'home',
    // component: () => import('../views/Front'),
    component: () => import("@/views/pages/LandingPage/index_kr"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import("@/views/pages/LandingPage/policy"),
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  //base: process.env.BASE_URL,
})


export default router