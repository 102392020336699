import BaseModel from '../core/BaseModel';
export default class MatchMemeberModel extends BaseModel {
    constructor() {
        super({ 
                name: "match_member",
                tableName: 'match_member', 
                label: "Members", 
                display_key: 'id', 
                default_order: { key: 'created_at', direction: 'asc' } ,
            },  
            {
            stt: { 
                name: "stt", 
                label: "No.", 
                width : 5,
                class: 'center',
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            uid: {
              name: 'uid',
              display: false
            },
            users: {
                refs: "user {fullname email clubs_member(where: {clubs: {deleted: {_eq: false }}}){id}}",
                display: false
            },
            clubs: {
                refs: `clubs {id club_name}`,
                display: false
            },
            club_name: {
                name: 'club_name',
                type: "String", 
                label: 'Club name',
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.club_name = '';
                }
            },
            fullname: {
                name: 'fullname',
                type: "String", 
                label: 'Fullname',
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.fullname = '';
                }
            },
            type_member: {
                name: 'type_member',
                type: "String", 
                label: 'type_member',
                display: false
            },
            type_of_player: {
                name: 'type_of_player',
                type: "String", 
                label: 'Type of player',
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.type_of_player = '';
                }
            },
            role_join: {
                name: 'role_join',
                type: "String", 
                label: 'role_join',
                display: false
            },
            role_in_club: {
                name: 'role_in_club',
                type: "String", 
                label: 'Role in club',
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.role_in_club = '';
                }
            },

            updated_at: {
                name: 'updated_at', 
                type: "Datetime", 
                label: 'Updated date',
                // filter: { type: 'DateRange', match: 'contains'},
                display: false
            },
        },)
    }

}