import BaseModel from '../core/BaseModel'
// import GroupSelectModel from "./GroupSelectModel";
//import gql from 'graphql-tag'
export default class GroupModel extends BaseModel {

    constructor() {
        super({ 
                name: "banner_type",
                tableName: 'banner_type',
                label: "banner_type",
                display_key: 'type',
                default_order: { key: 'ordinal', direction: 'asc' } ,
            },
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                class: "common",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
            },
            id: { 
                name: 'id', 
                type: "Bigint",
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                type: {
                    name: 'type',
                    type: "String",
                    label: 'Permission name',
                    class: 'width_50',
                    width: 15,
                    display:false
                },
                banner_id: {
                    name: 'banner_id',
                    type: "String",
                    label: 'Permission name',
                    class: 'width_50',
                    width: 15,
                    display:false
                },

        },)
    }

}
