import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class CountryModel extends BaseModel {

    constructor() {
        super({
                name: "country",
                tableName: 'country',
                label: "country",
                /*display_key: 'fullname',
                default_order: { key: 'updated_at', direction: 'asc' } */},
            {
            
                id  : {
                  name: 'id',
                  type: "Bigint",
                  label: 'Hình anh',
                  sortable: false,
                  width : 8,
                },
                ordinal  : {
                  name: 'url',
                  type: "Int",
                  label: 'Hình anh',
                  width : 8,
                },
                deleted: {
                  name: "deleted",
                  label: "Xóa",
                  type: "Checkbox",
                  display: false
                }
        },)
    }

}