import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
//import * as firebase from 'firebase/app'
//import config from './config/index';
import configJson from '../config.json';

var envConfig = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') ? process.env.NODE_ENV:'development';


// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

// Http endpoint DEV
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || configJson[envConfig].url_hasura;

const httpLink = new HttpLink({
  uri: httpEndpoint,
})
const handleGrapqlError = async function (error) {
  var { extensions } = error
  var bus = new Vue();
  var errTitle = 'Connection errors';
  var errMessage = 'Please reload the page and try again';
  console.log('extensions',extensions);
  if (extensions.code == 'invalid-jwt') {
    /*const currentUser = await firebase.getCurrentUser()
    await currentUser.getIdToken(true)*/
    setTimeout(function () {
      window.reload()
    }, 3000)
    location.reload();
    return;
  }
  if (extensions.code == 'constraint-violation') {
    errTitle = 'Error data'
    errMessage = 'Error binding between data, this operation cannot be performed'
  }
  bus.$toast.add({ severity: 'error', summary: errTitle, detail: errMessage, life: 5000 })
}

const errorHandler = onError(({ networkError, graphQLErrors }) => {
  var bus = new Vue();

  if (graphQLErrors)
    graphQLErrors.map(handleGrapqlError)

  if (networkError) {
    bus.$toast.add({ severity: 'error', summary: 'Connection errors', detail: 'Please check your internet connection', life: 5000 })
    // console.error(`[Network error]: ${networkError}`)
  }
})

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'wss://grow.engma.com.vn/v1/graphql',
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: errorHandler.concat(httpLink),

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

let stApolloClient

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler() {
      // eslint-disable-next-line no-console
      // console.error('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })

  stApolloClient = apolloClient

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (!apolloClient) apolloClient = stApolloClient
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch {
    // eslint-disable-next-line no-console
    // console.error('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (!apolloClient) apolloClient = stApolloClient
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch {
    // eslint-disable-next-line no-console
    // console.error('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
