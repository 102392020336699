import BaseService from './BaseService'
import UserModel from '../models/UserModel'
import AdminModel from '../models/AdminModel'
import AccountsModel from '../models/AccountsModel'
import NotificationsModel from '../models/NotificationsModel'

import FileModel from "@/models/FileModel";
import RelationshipFileModel from "../models/RelationshipFileModel";
import GroupModel from '../models/GroupModel'
import GroupUserModel from '../models/GroupUserModel'
import SettingModel from '../models/SettingModel'
import PolicyModel from '../models/PolicyModel'
import PolicyLangModel from '../models/PolicyLangModel'
import NotificationsLangModel from '../models/NotificationsLangModel'
import ControllerModel from '../models/ControllerModel'
// import StateModel from '../models/StateModel'
// import CategoryUrlModel from '../models/CategoryUrlModel'
// import UrlsModel from '../models/UrlsModel'
// import HistoryPointModel from '../models/HistoryPointModel'
import GuideModel from "../models/GuideModel";
import GuideLangModel from "../models/GuideLangModel";
import ReportReasonModel from "../models/ReportReasonModel";
import ReportUserModel from "../models/ReportUserModel";
import ReportReasonLangModel from "../models/ReportReasonLangModel";
import GroupSelectModel from "../models/GroupSelectModel";
import BannerModel from "../models/BannerModel";
import BannerTypeModel from "../models/BannerTypeModel";
import ExperienceModel from "../models/ExperienceModel";
import CountryModel from "../models/CountryModel";
import ReportPostModel from "../models/ReportPostModel";
import StadiumModel from "../models/StadiumModel";
import NumberOfPlayerModel from "../models/NumberOfPlayerModel";
import ProvinceModel from "../models/ProvinceModel";
import DistrictModel from "../models/DistrictModel";
import WardModel from "../models/WardModel";
import ClubsModel from '../models/ClubsModel'
import SkillModel from '../models/SkillModel'
import OperatingTimeModel from '../models/OperatingTimeModel'
import AgeModel from '../models/AgeModel'
// import StadiumModel from '../models/StadiumModel'
import ClubsMemberModel from '../models/ClubsMemberModel'
import JoinModel from '../models/JoinModel'
import ClubsOperatingTimeModel from '../models/ClubsOperatingTimeModel'
import ClubsPlayerExperienceModel from '../models/ClubsPlayerExperienceModel'
import ClubsAgeModel from '../models/ClubsAgeModel'
import MatchModel from '../models/MatchModel'
import PostModel from '../models/PostModel'
import MatchMemeberModel from '../models/MatchMemeberModel'

export default {
    services: {
      'setting': {
        'service': new BaseService(new SettingModel()),
        'model': new SettingModel()
      },

      'accounts': {
        'model': new AccountsModel(),
        'service': new BaseService(new AccountsModel())
      },
      'view_admin': {
        'model': new AdminModel(),
        'service': new BaseService(new AdminModel())
      },
      'group': {
        'model': new GroupModel(),
        'service': new BaseService(new GroupModel())
      },
      'group_select': {
        'model': new GroupSelectModel(),
        'service': new BaseService(new GroupSelectModel())
      },
      'group_user': {
        'model': new GroupUserModel(),
        'service': new BaseService(new GroupUserModel())
      },
      'users': {
        'model': new UserModel(),
        'service': new BaseService(new UserModel())
      },
      'file': {
        'model': new FileModel(),
        'service': new BaseService(new FileModel())
      },
      'relationship_file': {
        'model': new RelationshipFileModel(),
        'service': new BaseService(new RelationshipFileModel())
      },
      // 'notifications': {
      //     'service': new BaseService(new NotificationsModel()),
      //     'model': new NotificationsModel(),
      //     'rels': [],
      // },
      'notification': {
        'service': new BaseService(new NotificationsModel()),
        'model': new NotificationsModel()
      },
      'policy': {
        'service': new BaseService(new PolicyModel()),
        'model': new PolicyModel()
      },
      'policy_lang': {
        'service': new BaseService(new PolicyLangModel()),
        'model': new PolicyLangModel()
      },
      'guide': {
        'service': new BaseService(new GuideModel()),
        'model': new GuideModel()
      },
      'guide_lang': {
        'service': new BaseService(new GuideLangModel()),
        'model': new GuideLangModel()
      },
      'report_reason': {
        'service': new BaseService(new ReportReasonModel()),
        'model': new ReportReasonModel()
      },
      'report_user': {
        'service': new BaseService(new ReportUserModel()),
        'model': new ReportUserModel()
      },
      'report_post': {
        'service': new BaseService(new ReportPostModel()),
        'model': new ReportPostModel()
      },
      'number_of_player': {
        'service': new BaseService(new NumberOfPlayerModel()),
        'model': new NumberOfPlayerModel()
      },
      'report_reason_lang': {
        'service': new BaseService(new ReportReasonLangModel()),
        'model': new ReportReasonLangModel()
      },
      'banner': {
        'service': new BaseService(new BannerModel()),
        'model': new BannerModel()
      },
      'banner_type': {
        'service': new BaseService(new BannerTypeModel()),
        'model': new BannerTypeModel()
      },

      'lang_notification': {
        'service': new BaseService(new NotificationsLangModel()),
        'model': new NotificationsLangModel()
      },
      'controller': {
        'service': new BaseService(new ControllerModel()),
        'model': new ControllerModel()
      },
      'experience': {
        'service': new BaseService(new ExperienceModel()),
        'model': new ExperienceModel()
      },
      'country': {
        'service': new BaseService(new CountryModel()),
        'model': new CountryModel()
      },
      'province': {
        'service': new BaseService(new ProvinceModel()),
        'model': new ProvinceModel()
      },
      'district': {
        'service': new BaseService(new DistrictModel()),
        'model': new DistrictModel()
      },
      'ward': {
        'service': new BaseService(new WardModel()),
        'model': new WardModel()
      },
      'clubs': {
        'service': new BaseService(new ClubsModel()),
        'model': new ClubsModel()
      },
      'skill': {
        'service': new BaseService(new SkillModel()),
        'model': new SkillModel()
      },
      'operating_time': {
        'service': new BaseService(new OperatingTimeModel()),
        'model': new OperatingTimeModel()
      },
      'age': {
        'service': new BaseService(new AgeModel()),
        'model': new AgeModel()
      },
      'stadium': {
        'service': new BaseService(new StadiumModel()),
        'model': new StadiumModel()
      },
      'clubs_member': {
        'service': new BaseService(new ClubsMemberModel()),
        'model': new ClubsMemberModel()
      },
      'join': {
        'service': new BaseService(new JoinModel()),
        'model': new JoinModel()
      },
      'clubs_operating_time': {
        'service': new BaseService(new ClubsOperatingTimeModel()),
        'model': new ClubsOperatingTimeModel()
      },
      'clubs_player_experience': {
        'service': new BaseService(new ClubsPlayerExperienceModel()),
        'model': new ClubsPlayerExperienceModel()
      },
      'clubs_age': {
        'service': new BaseService(new ClubsAgeModel()),
        'model': new ClubsAgeModel()
      },
      'match': {
        'service': new BaseService(new MatchModel()),
        'model': new MatchModel()
      },
      'post': {
        'service': new BaseService(new PostModel()),
        'model': new PostModel()
      },
      'match_member': {
        'service': new BaseService(new MatchMemeberModel()),
        'model': new MatchMemeberModel()
      },
    },
    getModel(modelName) {
      return this.services[modelName].model
    },
    getList(modelName, params) {
      return this.services[modelName].service.getListQuery(params);
    },
    countData(modelName) {
      return this.services[modelName].service.countDataQuery();
    },
    updateData(modelName) {
        return this.services[modelName].service.updateDataQuery();
    },
    insertData(modelName) {
      return this.services[modelName].service.insertDataQuery();
    },
    deleteData(modelName) {
      return this.services[modelName].service.deleteDataQuery();
    },
    getByMyId(modelName,params) {
      return this.services[modelName].service.getByMyIdQuery(params);
    },
};
