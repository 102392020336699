import BaseModel from '../core/BaseModel'
export default class WardModel extends BaseModel {

    constructor() {
        super({
                name: "ward",
                tableName: 'ward',
                label: "ward",
                /*display_key: 'fullname',
                default_order: { key: 'updated_at', direction: 'asc' } */},
            {
            
              id  : {
                name: 'id',
                type: "Bigint",
                label: 'ID',
                sortable: false,
                width : 8,
              },
              ordinal  : {
                name: 'ordinal',
                type: "Int",
                label: 'ordinal',
                width : 8,
              },
              deleted: {
                name: "deleted",
                label: "Xóa",
                type: "Checkbox",
                display: false
            }
        },)
    }

}