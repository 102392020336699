import Common from "./Common";
import GetConstants from './Constants';
var Constants = GetConstants.getConstans();
import ApiRepository from "./ApiRepository";
import store from '@/store'
import commonFuction from "./Common";
import moment from "moment-timezone";
import router from '../router';
export default {
    async setStateLogin(dataSave) {
        var dataState = {
            uid:(!Common.isEmpty(dataSave.uid)) ? dataSave.uid:'',
            token:(!Common.isEmpty(dataSave.token)) ? dataSave.token:'',
            token_refesh:(!Common.isEmpty(dataSave.token_refesh)) ? dataSave.token_refesh:'',
            dateChangeToken:(moment().tz("UTC").format('YYYYMMDDHHmmss')).toString()
        }
        localStorage.setItem('state_login', JSON.stringify(dataState));
    },
    async getStateLogin() {
        return (localStorage.getItem('state_login')) ? JSON.parse(localStorage.getItem('state_login')) : null;
    },
    async getToken() {
        return (await this.getStateLogin()) ? ((await this.getStateLogin()).token):null;
    },
    async getUid() {
        return (await this.getStateLogin()) ? ((await this.getStateLogin()).uid):null;
    },
    async refeshToken() {
        var state_login =await this.getStateLogin();
        if (!commonFuction.isEmpty(state_login)){
            console.log("state_loginstate_login:",state_login)
            //Nếu tồn tại login thì check điều kiện refesh token
            //Lấy thời gian hiện tại trừ đi 10 phút
            var minus_time = moment().tz("UTC").subtract(60*10, 'seconds').format('YYYYMMDDHHmmss'); //-10phut //60*10
            var dateChangeToken = state_login.dateChangeToken;
            console.log("dateChangeToken:",dateChangeToken);
            console.log("minus_time:",minus_time);
            var token_refesh = state_login.token_refesh;
            if (parseFloat(minus_time) > parseFloat(dateChangeToken)){
                var dataRequest = {
                    token_refesh:token_refesh,
                    minus_time:minus_time,//log date
                    dateChangeToken:dateChangeToken,//log date
                }
                //Gọi API refeshtoken
                ApiRepository.post(Constants.API.REFESH_TOKEN,dataRequest).then(async (response) => {
                    var dataSuccess = response.data;
                    var dataUser = dataSuccess.data;
                    console.log("dataUserdataUserdataUser:",dataUser)
                    store.commit('setUser', dataUser.id)
                    store.commit('setStatus', 'success')
                    store.commit('setError', null)
                    store.commit('setToken', dataUser.token);
                    store.commit('setTokenRefesh', dataUser.token_refesh);
                    var dataState = {
                        uid:dataUser.id,
                        token:dataUser.token,
                        token_refesh:dataUser.token_refesh,
                    }
                    this.setStateLogin(dataState);
                })
                    .catch((error) => {
                        console.log("errorerror:",error)
                        //logout
                        this.clearDataLogout();
                        /*store.commit('setStatus', 'failure')
                        store.commit('setError', error.message)*/
                        //
                    })
            }
        }
    },
    async checkToken(token) {
        const headers = {
            "Authorization": "Bearer "+token,
        };
       var dataRes = false
      await  ApiRepository.post(Constants.API.CHECK_TOKEN_ADMIN, {}, {headers}).then(async (res) => {
          var dataSuccess = res.data;
          var dataUser = dataSuccess.data;
          dataRes= dataUser;
        })
        .catch(error => {
            console.log(error);
            //this.errorMessage = error.message;
            /*var message = error.response.data.message;
            dataRes.status = 2;
            dataRes.message = message;*/
            //API này nếu gặp lỗi thì logout
            this.clearDataLogout();
        });
       return dataRes;
    },
    async clearDataLogout() {
        store.commit('setUser', null)
        store.commit('setStatus', 'success')
        store.commit('setError', null)
        store.commit('setToken', null)
        store.commit('setIsLoggedIn', false)
        //localStorage.removeItem("state_login");
        this.getStateLogin().then(async state_login => {
            if (!commonFuction.isEmpty(state_login)){
                //Nếu tồn tại token thì gọi API clear token server
                var token = state_login.token;
                var token_refesh = state_login.token_refesh;
                const headers = {
                    "Authorization": "Bearer "+token,
                };
                await  ApiRepository.post(Constants.API.LOGOUT_ADMIN, {token_refesh:token_refesh}, {headers}).then(() => {
                    console.log("logout thành công");
                })
                .catch(error => {
                        console.log("logout :",error);
                });
            }
        })
        //xóa hết localstrore
        localStorage.clear();
       // window.location.href = '/auth/login';
       router.push('/')
    },

}
