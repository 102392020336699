import BaseModel from '../core/BaseModel';
export default class ClubsMemberModel extends BaseModel {
    constructor() {
        super({ 
                name: "clubs_member",
                tableName: 'clubs_member', 
                label: "Members", 
                display_key: 'id', 
                default_order: { key: 'created_at', direction: 'asc' } ,
            },  
            {
            stt: { 
                name: "stt", 
                label: "No.", 
                width : 5,
                class: 'center',
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            uid: {
                name: 'uid',
                display: false
            },
            users: {
                refs: "users {fullname email clubs_member(where: {clubs: {deleted: {_eq: false }}}){id}}",
                display: false
            },
            clubs: {
                refs: `clubs {id club_name skill{id skill_lang(where: {lang: {_eq: "en"}}){name}} club_operating_time { operating_time { id operating_time_lang (where: {lang: {_eq: "en"}}) {name}}} country{id country_lang(where: {lang: {_eq: "en"}}) {name}} province{id province_lang(where: {lang: {_eq: "en"}}) {name}} district{id district_lang(where: {lang: {_eq: "en"}}) {name}} clubs_member(where: {clubs: {deleted: {_eq: false }}}){uid role users{fullname}} }`,
                display: false
            },
            fullname: {
                name: 'fullname',
                type: "String", 
                label: 'Fullname',
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.fullname = '';
                }
            },
            email: {
                name: 'email',
                type: "String", 
                label: 'Email',
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.email = '';
                }
            },
            clubs_member: {
                name: 'clubs_member',
                type: "CustomUrl",
                class: "center",
                label: 'Joined club',
                computed: (data) => {
                    data.clubs_member = 0;
                }
            },
            role: {
                name: 'role', 
                type: "Selection", 
                label: 'Role',
                options: [{
                    value: 'member',
                    label: 'Member'
                },
                {
                    value: 'admin',
                    label: 'Admin'
                },
                {
                    value: 'owner',
                    label: 'Owner'
                }
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            },
            join_date: {
                name: 'join_date', 
                type: "Datetime", 
                label: 'Member since',
                filter: { type: 'DateRange', match: 'contains'},
            },
            updated_at: {
                name: 'updated_at', 
                type: "Datetime", 
                label: 'Updated date',
                filter: { type: 'DateRange', match: 'contains'},
            },
            status: {
                name: 'status', 
                type: "Selection", 
                label: 'Status',
                options:[{
                    value: 'waiting',
                    label: 'Waiting for approve',
                },{
                    value: 'approved',
                    label: 'Approved'
                },{
                    value: 'rejected',
                    label: 'Rejected'
                },{
                    value: 'leaved',
                    label: 'Leaved'
                }],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            }
        },)
    }

}