import BaseModel from '../core/BaseModel'

export default class NotificationsModel extends BaseModel {

    constructor() {
        super({
            tableName: 'notification', label: "Notification", display_key: 'id'
        }, {
            stt: { 
                name: "stt", 
                label: "No.", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: {
                name: "id",
                type: "Uuid",
                label: "ID",
                primaryKey: true,
                display: false,
              },
            type: {
                name: "type",
                label: "Receiver",
                type: "Selection",
                // custom: 'SelectionText',
                width: 10,
                options: [
                    { value: 'ALL_USER', label: 'All of user',style: 'color:  #000000;'},
                    { value: 'ALL_CLUB', label: 'All of club',style: 'color:  #000000;'},
                    { value: 'CLUB', label: 'Clubs',style: 'color:  #000000;'},
                    { value: 'USER', label: 'Users',style: 'color:  #000000;'},
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
            },
            title: { 
                name: 'title', 
                type: "String", 
                label: 'Title', 
                computed: (data) => {
                    data.title = '';
                }
            },
            short_description: { 
                name: 'short_description', 
                type: "String", 
                label: 'Short description',
                computed: (data) => {
                    data.short_content = '';
                }
            },
            action_key : {
                name: 'action_key', 
                type: "String", 
                label: 'Hành động',
                display: false
            },
            is_push: {
                name: 'is_push', 
                type: 'Boolean', 
                label: 'Send push',
                display: false
            },
            is_admin: {
                name: 'is_admin', 
                type: 'Boolean', 
                label: 'Show admin',
                display: false
            },
            created_at : { 
                name: 'created_at', 
                type: "Datetime", 
                width: 10,
                label: 'Created date',
                filter: { type: 'DateRange', match: 'contains'}
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime", 
                display: false,
                label: 'Updated date',
                filter: { type: 'DateRange', match: 'contains'}
            },
            rel_data : {
                refs: `
                    notification_lang(where: {lang: {_eq: "en"}}){lang title short_description description}
                `,
                display: false
              }
            
        })
    }

}