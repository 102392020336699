import BaseModel from "../core/BaseModel";

export default class NumberOfPlayerModel extends BaseModel {
  constructor() {
    super(
      {
        name: "number_of_player",
        tableName: "number_of_player",
        label: "number_of_player",
        display_key: "code",
      },
      {
        stt: { 
          name: "stt", 
          label: "No.",
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },

        code: {
          name: "code",
          label: "code",
          type: "String",
          class: " width-2",
        },
        // plain_name: {
        //   name: "plain_name",
        //   label: "plain_name",
        //   type: "String",
        //   class: " width-2",
        // },


      //   banner_type: {
      //     refs:"banner_type(where: {deleted: {_eq: false}}) { type }",
      //     name:"banner_type",
      //     type: "Selection",
      //     label: "Type of banner",
      //     custom: 'SelectionText',
      //     class:" width-1",
      //     options: [
      //       {value: 'Large', label: 'Large', style: 'color:  #000000;'},
      //       {value: 'Medium', label: 'Medium',style: 'color:  #000000;'},
      //       {value: 'Small', label: 'Small',style: 'color:  #000000;'}
      //     ],
      //     filter: {type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
      //   },
      //
      //   link: {
      //     name: "link",
      //     type: "String",
      //     label: "Link",
      //     class: "link-banner width-2",
      //   },
      //
      //
      //   date_from: {
      //     name: "from_to",
      //     label: 'From-to',
      //     type: "Selection",
      //     custom: 'SelectionText',
      //     filter: {type: 'DateRange', match: 'contains'},
      //     class:" width-2",
      //   },
      //
      //
      //   date_to : {
      //     name: 'date_to',
      //     type: "Selection",
      //     label: "date_to ",
      //     custom: 'SelectionText',
      //     display: false,
      //
      //   },
      //
      //   active: {
      //     name: "active",
      //     label: "Show",
      //     type: "Selection",
      //     custom: 'SelectionText',
      //     options: [
      //       {value: 'true', label: 'Active', style: 'color:  #000000;'},
      //       {value: 'false', label: 'Unactive',style: 'color:  #000000;'}
      //     ],
      //     filter: {type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
      //     class:"mid width-1",
      //   },
      //   updated_by_id: {
      //     refs:"updated_by_id { fullname}",
      //     type: "Selection",
      //     label: "Updated by",
      //     custom: 'SelectionText',
      //     class:" width-1",
      //   },
      //   updated_at : {
      //     name: 'updated_at',
      //     type: "Datetime",
      //     width: 12,
      //     label: 'Updated date',
      //     class:" width-1",
      //     filter: {type: 'DateRange', match: 'contains'},
      //   },
      //   updated_by : {
      //     name: 'updated_by',
      //     type: "String",
      //     width: 12,
      //     label: 'updated_by',
      //     display:false
      //   },
      //   deleted: {
      //     name: "deleted",
      //     type: "String",
      //     label: "Guide name",
      //     display: false
      //   },
      //   created_at : {
      //     name: 'created_at',
      //     type: "Datetime",
      //     label: 'Ngày tạo',
      //     display: false,
      //
      // },
      //   key_refs: {
      //     refs: `relationship_file { file { relationship_files { id } url } }`,
      //     display: false
      //   }


      }
    );
  }
}
