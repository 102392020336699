import BaseModel from '../core/BaseModel'
// import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class AdminModel extends BaseModel {

    constructor() {
        super({ 
                name: "view_admin",
                tableName: 'view_admin',
                label: "Administrator", 
                display_key: 'fullname', 
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Fullname',
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },
                account: {
                    name: 'account',
                    type: "String",
                    label: 'Username',
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                phone: {
                    name: 'phone',
                    type: "String",
                    label: 'Phone',
                    sortable: false,
                    width: 10,
                    filter: {
                        type: 'input',
                        match: 'contains'
                    },
                },
                rel_object_group_uses: {
                    refs: `rel_object_group_uses {
                            group_id
                        }`,
                    display: false
                },
                name_group: {
                    name: 'name_group',
                    type: "String",
                    label: 'Permission',
                    filter: {
                        type: 'input',
                        match: 'contains'
                    },
                },
                created_at : { 
                    name: 'created_at', 
                    type: "Datetime", 
                    width: 10,
                    label: 'Created date',
                    display: false, 
                    sortable: true,
                },
                updated_at : { 
                    name: 'updated_at', 
                    type: "Datetime", 
                    label: 'Updated date',
                    width: 10,
                    filter: { type: 'DateRange', match: 'contains'}
                },
                active: {
                    name: "active",
                    label: "Hiển thị",
                    type: "Checkbox",
                    display : false
                },
                // name_group_filter: {
                //     name: 'name_group_filter',
                //     type: "Selection",
                //     label: 'Nhóm quyền',
                //     sortable: false,
                //     width: 10,
                //     models: new GroupModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     computed: (data) => {
                //         //data.name_group_filter = data.name_group;
                //         if(data.rel_object_group_uses !== undefined && data.rel_object_group_uses !== null){

                //                 data.name_group_filter = data.rel_object_group_uses.group_id;
                //                 //data.rel_object_group_uses.group
                //         }else{
                //             data.name_group_filter = '';
                //         }
                //     }
                // },

               /* name_group: {
                    name: "name_group",
                    label: "Nhóm quyền",
                    width: 4,
                    type: "Selection",
                    models: new CategoryParentModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    /!*computed: (data) => {
                        data.name_group = '';
                    }*!/
                },*/
                
                email: {
                    name: 'email',
                    type: "String",
                    label: 'Email',
                    sortable: false,
                    width: 10,
                    display: false

                },
        },)
    }

}