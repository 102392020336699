import BaseModel from "../core/BaseModel";

export default class GuideLangModel extends BaseModel {
  constructor() {
    super(
      {
        name: "guide_lang",
        tableName: "guide_lang",
        label: "Guide Lang",
        display_key: "guide_id",
      },
      {

        guide_id: {
          name: "guide_id",
          type: "bigint",
          label: "ID",
          primaryKey: true,
        },
        lang: {
          name: "lang",
          type: "Text",
          label: "Nội dung",
          display: false,
        },
        title: {
          name: "title",
          type: "Text",
          label: "Nội dung",
          display: false,
        },
        plain_title: {
          name: "plain_title",
          type: "Text",
          label: "Nội dung",
          display: false,
        },
        infomation: {
          name: "infomation",
          type: "Text",
          label: "Nội dung",
          display: false,
        },
      }
    );
  }
}
