import BaseModel from '../core/BaseModel';
export default class ClubsAgeModel extends BaseModel {
    constructor() {
        super({ 
                name: "clubs_age",
                tableName: 'clubs_age', 
                label: "Skill Management", 
                display_key: 'id', 
                default_order: { key: 'created_at', direction: 'asc' } ,
            },  
            {
            stt: { 
                name: "stt", 
                label: "No.", 
                width : 5,
                class: 'center',
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            }
        },)
    }

}