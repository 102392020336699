import BaseModel from '../core/BaseModel'
import GroupSelectModel from "./GroupSelectModel";
//import gql from 'graphql-tag'
export default class GroupModel extends BaseModel {

    constructor() {
        super({ 
                name: "group",
                tableName: 'group',
                label: "Permission",
                display_key: 'name',
                default_order: { key: 'ordinal', direction: 'asc' } ,
            },
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                class: "common",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Bigint",
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                name: {
                    name: 'name',
                    type: "Selection",
                    custom: 'SelectionText',
                    label: 'Permission name',
                    class: 'width_50',
                    width: 15,
                    models: new GroupSelectModel(),
                    // options:[],
                    filter: {type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
                },
                // code : {
                //     name: 'code',
                //     type: "Selection",
                //     label: 'Mã kênh',
                //     custom: 'SelectionText',
                //     models: new ChannelSelectModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     sortable: false,
                //     class:"width_160 common",
                // },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    width: 12,
                    label: 'Updated date',
                    filter: {type: 'DateRange', match: 'contains'},
                },
                group_roles: {
                    refs: `group_roles {
                          id
                          role_id
                        }`,
                    display: false
                }
        },)
    }

}
